import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/shared/layout";
import { Seo } from "../../components/shared/seo";
import { ProjectsTypeNavbar } from "../../components/projectsType/projectsTypeNavbar";
import { LinkTypeBig } from "../../components/shared/links";
// NO NEED FOR THIS PAGE!!!!!!
const ProjectTypesPage = (props) => {
  // --->  Data <--- //
  const projectTypes = props.data.allStrapiProjectType.nodes;

  return (
    <Layout
      location={props.location}
      title={props.data.site.siteMetadata.title}
    >
      <ProjectsTypeNavbar />

      {/* ----- Project Grid ----- */}

      <div className="flex flex-wrap xs:gap-x-xl xs:gap-y-md md:gap-x-xxl md:gap-y-xl">
        {projectTypes.map((projectType, index) => {
          return (
            <LinkTypeBig
              slug={`project-type`}
              type={projectType.projectType.toLowerCase()}
              key={index}
              isMedium={false}
              classes={" last:mr-0"}
            />
          );
        })}
      </div>
    </Layout>
  );
};
export const query = graphql`
  query ProjectTypesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiProjectType {
      nodes {
        projectType
        projects {
          title
          heroImage {
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectTypesPage;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Projects"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
